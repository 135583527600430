import React from 'react';
import PropTypes from 'prop-types';

function Meta({ name, property, content }) {
  if (property !== undefined) {
    return (
      <meta key={property} property={property} content={content} />
    );
  }
  return (
    <meta key={name} name={name} content={content} />
  );
}

Meta.defaultProps = {
  name: undefined,
  property: undefined,
};

Meta.propTypes = {
  content: PropTypes.string.isRequired,
  name: PropTypes.string,
  property: PropTypes.string,
};

export default Meta;
