import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import CssBaseline from '@material-ui/core/CssBaseline';
import Meta from './meta';
import Logo from '../images/logo.png';

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        creator
        site
        hostname
      }
    }
  }
`;

function SEO({
  description, lang, meta, title,
}) {
  const { site: { siteMetadata } } = useStaticQuery(query);
  const metaDescription = description || siteMetadata.description;
  const metas = [
    {
      name: 'apple-mobile-web-app-capable',
      content: 'yes',
    },
    {
      name: 'mobile-web-app-capable',
      content: 'yes',
    },
    {
      name: 'description',
      content: metaDescription,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: metaDescription,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: `https://${siteMetadata.hostname}${Logo}`,
    },
    {
      property: 'fb:app_id',
      content: '958961151213707',
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:creator',
      content: siteMetadata.creator,
    },
    {
      name: 'twitter:site',
      content: siteMetadata.site,
    },
  ];
  return (
    <>
      <Helmet htmlAttributes={{ lang }} title={title} titleTemplate={`%s @ ${siteMetadata.title}`}>
        {metas.concat(meta).map(Meta)}
      </Helmet>
      <CssBaseline />
    </>
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
